import gql from 'graphql-tag';

export const UPDATE_PASSWORD_LOGGED_IN = gql`
mutation UpdatePasswordLoggedIn(
  $currentPassword: String
  $newPassword: String
) {
  updatePasswordLoggedIn(
    currentPassword: $currentPassword
    newPassword: $newPassword
  ) {
    success
    wrongPassword
  }
}`;
