import gql from 'graphql-tag';

export const UPDATE_SUGGESTION = gql`
mutation SetDataSheetChange($dataSheetChange: DataSheetChangeUpdateInput) {
  setDataSheetChange(dataSheetChange: $dataSheetChange){
    id
    applicantEmail
    applicantFirstName
    applicantLastName
    carLot {
      id
      companyId
      name
    }
    brand
    model
    version
    description
    status
    createdAt
  }
}`;
