import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SEND_REGISTRATION } from '../../mutations';
import {
  validateEmail,
  validateCuilCuit,
  validateIsRequired,
  validateInputText,
  validateDNI,
  validatePhoneNumber,
}from '../../utils/validators.utils';
import SuccessRegistration from '../../components/register-form/SuccessRegistration.component';
import RegisterForm from '../../components/register-form/RegisterForm.component';
import LoadingModal from '../../components/common/LoadingModal.component';

const RegisterFormContainer = ({ showLogin, termsAndPolicy }) => {
  const [submitError, setSubmitError] = React.useState('');

  const validateFields = (fields) => {
    if(submitError) {
      setSubmitError('');
    }
    const isValidCuilCuit = fields.cuilCuit ? validateCuilCuit(fields.cuilCuit) : { valid: true, error: '' };
    const isValidEmail = validateEmail(fields.email, 'El e-mail no es válido. Intentá de nuevo.', 'Necesitamos tu e-mail para crear tu cuenta.');
    const isValidName = validateInputText(fields.name, 'Necesitamos tu nombre para crear tu cuenta.');
    const isValidLastName = validateInputText(fields.lastName, 'Necesitamos tu apellido para crear tu cuenta.');
    const isValidDni = validateDNI(fields.dni, 'Necesitamos tu DNI para crear tu cuenta.');
    const isValidPhone = validatePhoneNumber(fields.phone, 'Necesitamos tu teléfono para crear tu cuenta.');
    const isValidFront = validateIsRequired(fields.frontPhoto, 'La imagen es requerida');
    const isValidSide = validateIsRequired(fields.sidePhoto, 'La imagen es requerida');
    const isValidInside = validateIsRequired(fields.insidePhoto, 'La imagen es requerida');

    const valid =
      isValidCuilCuit.valid &&
      isValidEmail.valid &&
      isValidName.valid &&
      isValidLastName.valid &&
      isValidDni.valid &&
      isValidPhone.valid &&
      isValidFront.valid &&
      isValidSide.valid &&
      isValidInside.valid &&
      fields.checked;

    const errors = {
      cuilCuit: isValidCuilCuit,
      name: isValidName,
      lastName: isValidLastName,
      email: isValidEmail,
      dni: isValidDni,
      phone: isValidPhone,
      frontPhoto: isValidFront,
      sidePhoto: isValidSide,
      insidePhoto: isValidInside,
    };
    return { valid, errors };
  };

  const [sendRegistration, { loading, data }] = useMutation(SEND_REGISTRATION, {
    onCompleted: () => {
      showLogin(false);
    },
    onError: (e) => setSubmitError(e.message),
  });

  const register = (registerForm) => {
    sendRegistration({ variables: { form: registerForm } });
  };

  const success = data?.register.success || false;

  return (
    <React.Fragment>
      {success ? (
        <SuccessRegistration />
      ) : (
        <>
          <RegisterForm
            termsAndPolicy={termsAndPolicy}
            validateFields={validateFields}
            register={register}
            submitError={submitError}
          />
          <LoadingModal open={loading} />
        </>
      )}
    </React.Fragment>
  )
};

export default RegisterFormContainer;
