import React, { useState } from 'react';
import { Box, Grid, makeStyles, FormHelperText } from '@material-ui/core';
import Text from '../../common/text.component';
import Tooltip from '../../common/Tooltip.component';
import Dropzone from '../../common/Dropzone.component';
import DropzoneInfo from '../../common/DropzoneInfo.component';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyle = makeStyles(
  (theme) => ({
    leyendContainer: {
      margin: `${theme.spacing(4)}px 0`
    },
    tooltip: {
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(2),
      }
    },
    dropzoneContainer: {
      width: '8rem',
      height: '8rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '6rem',
      }
    },
    item: {
      display: 'flex',
      justifyContent: 'center'
    },
  })
);

// eslint-disable-next-line max-len
const SECTION_LEYEND = 'Para corroborar que sos un usuario “Reventa” necesitamos que subas fotos de distintas vistas de tu local.';
const SECTION_TOOLTIP = 'La imagen no debe superar los 5MB El formato debe ser JPG o PNG.';
const FRONT_LABEL = 'Frente';
const INSIDE_LABEL = 'Interior';
const SIDE_LABEL = 'Lateral';
const ERROR_MESSAGE = 'La imagen no cumple con los criterios requeridos. Por favor verifique y vuelva a intentarlo.';
const INITIAL_ERRORS = {
  frontPhoto: false,
  insidePhoto: false,
  sidePhoto: false,
};
const DropZoneSection = ({
  onPhotoUpload = () => {},
  onPhotoDelete = () => {},
  onPhotoRejected = () => {},
  errorMessages = {} }) => {
  const classes = useStyle();

  const [error, setError] = useState(false);
  const [errors, setErrors] = useState(INITIAL_ERRORS);

  React.useEffect(() => {
    if (errorMessages) {
      const newErrors = { ...errors };
      newErrors['frontPhoto'] = !errorMessages.frontPhoto.valid;
      newErrors['sidePhoto'] = !errorMessages.sidePhoto.valid;
      newErrors['insidePhoto'] = !errorMessages.insidePhoto.valid;

      const isError = newErrors.frontPhoto
        || newErrors.insidePhoto
        || newErrors.sidePhoto;

      setErrors(newErrors);
      setError(isError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages]);

  const renderDropzoneInfo = (isDragActive) => <DropzoneInfo isDragActive={isDragActive} />;

  const validationProps = {
    accept: ['image/gif', 'image/png', 'image/jpeg', 'image/bmp'],
    maxSize: 5000000
  };

  const handleError = (id, hasError) => {
    const newErrors = { ...errors };
    newErrors[id] = hasError;

    const isError = newErrors.frontPhoto
      || newErrors.insidePhoto
      || newErrors.sidePhoto;

    setErrors(newErrors);
    setError(isError);
    return isError;
  };

  const handleOnDrop = (accepted, rejected, id) => {
    if (accepted.length > 0) {
      const newError = handleError(id, false);
      onPhotoUpload(id, accepted[0], newError);
    }
    if (rejected.length > 0) {
      const newError = handleError(id, true);
      onPhotoRejected(id, rejected[0], newError);
    }
  };

  const SmallHelperIcon = () => (
    <Box fontSize="20px">
      <HelpOutlineIcon fontSize="inherit" color="primary" />
    </Box>
  );

  return (
    <Box>
      <Box
        className={classes.leyendContainer}
        display="flex"
        alignItems="center"
      >
        <Box
          display='flex'
        >
          <Text little>{SECTION_LEYEND}</Text>
        </Box>
        <Box
          display='flex'
          flexShrink={1}
          justifyContent='center'
          alignItems='center'
          height='100%'
          className={classes.tooltip}
        >
          <Tooltip AnchorComponent={SmallHelperIcon}>
            <Text>{SECTION_TOOLTIP}</Text>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.item} >
            <Dropzone
              className={classes.dropzoneContainer}
              error={errors.frontPhoto}
              label={FRONT_LABEL}
              {...validationProps}
              onDrop={(accepted, rejected) => handleOnDrop(accepted, rejected, 'frontPhoto')}
              onDelete={() => onPhotoDelete('frontPhoto', error)}
            >
              {renderDropzoneInfo}
            </Dropzone>
          </Grid>
          <Grid item xs={12} md={4} className={classes.item} >
            <Dropzone
              className={classes.dropzoneContainer}
              error={errors.insidePhoto}
              label={INSIDE_LABEL}
              {...validationProps}
              onDrop={(accepted, rejected) => handleOnDrop(accepted, rejected, 'insidePhoto')}
              onDelete={() => onPhotoDelete('insidePhoto', error)}
            >
              {renderDropzoneInfo}
            </Dropzone>
          </Grid>
          <Grid item xs={12} md={4} className={classes.item} >
            <Dropzone
              className={classes.dropzoneContainer}
              error={errors.sidePhoto}
              label={SIDE_LABEL}
              {...validationProps}
              onDrop={(accepted, rejected) => handleOnDrop(accepted, rejected, 'sidePhoto')}
              onDelete={() => onPhotoDelete('sidePhoto', error)}
            >
              {renderDropzoneInfo}
            </Dropzone>
          </Grid>
        </Grid>
      </Box>
      {error && <FormHelperText error>
        {ERROR_MESSAGE}
      </FormHelperText>}
    </Box>
  );
};

export default DropZoneSection;
