import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation Login($loginInput: LoginInput) {
    login(loginInput: $loginInput) {
      token
      profile {
        firstname
        lastname
        email
        role_name
        phone
      }
    }
  }
`;
