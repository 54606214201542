import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Button from '../common/button.component';
import Text from '../common/text.component';
import LogoBanner from '../common/LogoBanner.component';
import { goToHome } from '../../api/navigation';

const usesStyle = makeStyles({
  link: {
    padding: '0.5rem'
  },
  confirmationText: {
    paddingTop: '3.75rem',
    paddingBottom: '1rem',
  }

});

const SuccessRegistration = () => {
  const classes = usesStyle();

  return (
    <LogoBanner width="80%" marginTop='1.5rem' margin="auto" marginBottom='1.5rem'>
      <Box textAlign="left" marginTop='1rem' marginBottom='1rem' lineHeight="1.5">
        <Box marginBottom='1rem'>
          <Text bold large>Tu usuario está pendiente de aprobación.</Text>
        </Box>
        <Box marginBottom='1rem'>
          <Text >
            <Box marginBottom='0.5rem'>¡Gracias por registrarte!</Box>
            Estamos revisando tu solicitud. Si la aprobamos,
            <strong> en las próximas 48 hs. recibirás un mail con instrucciones para iniciar sesión. </strong>
          </Text>
        </Box>
        <Box marginBottom='1rem'>
          <Text >
            <strong>Si no te llegó la aprobación en 48 hs</strong>, puede ser porque:
          </Text>
        </Box>
        <Box marginBottom='1rem' paddingLeft="2rem">
          <ul>
            <li>No pudimos validar que seas un Reventa.
              <Box>
                <Text little cursive>Por las dudas te recordamos que sólo las personas
               que se dediquen a la compra y venta de autos podrán registrarse.</Text>
              </Box>
            </li>
            <li>El mail llegó a Spam.
              <Box>
                <Text little cursive>Asegurate de revisar todas las carpetas de tu mail.</Text>
              </Box>
            </li>
          </ul>
        </Box>
      </Box>
      <Button
        primary
        className={classes.link}
        onClick={() => goToHome()}>Volver a Carmuv</Button>
    </LogoBanner>
  );
};

export default SuccessRegistration;
