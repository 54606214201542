import gql from 'graphql-tag';

export const SEND_RESERVATION_EMAIL = gql`
  mutation sendReservationEmail($id: String, $email: String, $comments: String) {
    processReserve(id: $id, email: $email, comments: $comments) {
      id
      applicantFirstName
      applicantLastName
      brand
      model
      version{
        id
        name
      }
      carLot{
        id
        name
        companyId
      }
      vehicleColor
      price
      clientFirstName
      clientLastName
      numberPhone
      state
      createdAt
      year
    }
  }
`;
