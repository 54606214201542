import gql from 'graphql-tag';

export const CLOSE_RESERVE = gql`
  mutation closeReserve($id: String, $state: ReserveState, $reasonForCancellation: String) {
    closeReserve(
      id: $id
      state: $state
      reasonForCancellation: $reasonForCancellation
    ){
      id
      applicantFirstName
      applicantLastName
      applicantEmail
      brand
      model
      version{
        id
        name
      }
      carLot{
        id
        name
        companyId
      }
      vehicleColor
      price
      clientFirstName
      clientLastName
      numberPhone
      state
      reasonForCancellation
      createdAt
      year
    }
  }
`;
