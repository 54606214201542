import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Form from './components/Form.component';
import Text from '../common/text.component';
import PropTypes from 'prop-types';

// eslint-disable-next-line max-len
const REGISTER_TITLE ='Crear cuenta comercial'
const REGISTER_LEYEND = 'Si tu actividad es la compra y venta de autos con fines comerciales por favor completa los siguientes datos.';
const FIELDS_INITIAL_VALUE = {
  cuilCuit: '',
  name: '',
  lastName: '',
  email: '',
  dni: '',
  phone: '',
  frontPhoto: null,
  sidePhoto: null,
  insidePhoto: null,
  checked: false,
};

const useStyle = makeStyles(
  (theme) => ({
    container: {
      width: '25rem',
      maxHeight:'100vh',
      [theme.breakpoints.down('sm')] : {
        padding: '1.25rem 1.125rem 0 1.125rem'
      },
      [theme.breakpoints.up('md')]:{
        width:'60%'
      }
    },
    titleContainer:{
      marginTop:theme.spacing(6),
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    },
    title:{
      color:theme.palette.grayScale.g500,
      textAlign: 'center',
      fontSize:theme.typography.size.large,
      fontWeight:'600',
      marginBottom: '0.75rem',
    },
    legend: {
      marginTop:theme.spacing(1),
      textAlign: 'center',
      fontSize: theme.typography.size.little,
      [theme.breakpoints.down('sm')]: {
        fontWeight:'400'
      }
    },
  })
);

const RegisterForm = ({ validateFields, register, submitError, formFields, termsAndPolicy }) => {
  const classes = useStyle();
  const [isFormValid, setIsFormValid] = useState(true);
  const [errorMessages, setErrorMessages] = useState(null);

  const handleOnSubmit = (fields, isValid) => {
    if(isValid){
      const { checked, ...registerFields } = fields;
      register(registerFields);
    }
  };

  const validateForm = (fields) => {
    const { valid, errors } = validateFields(fields);
    setIsFormValid(valid);
    setErrorMessages(errors);
    return valid;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <Box className={classes.title}>
          <Text>{REGISTER_TITLE}</Text>
          </Box >
          <Box className={classes.legend}>
          <Text >{REGISTER_LEYEND}</Text>
          </Box>
        </Box>
        <Form
          termsAndPolicy={termsAndPolicy}
          onSubmit={handleOnSubmit}
          validateForm={validateForm}
          valid={isFormValid}
          errorMessages={errorMessages}
          initialFields={formFields}
          submitError={submitError}
        />
      </Box>
    </Box>
  );
};

RegisterForm.propTypes = {
  validateFields : PropTypes.func,
  register : PropTypes.func,
  submitError : PropTypes.node,
  formFields : PropTypes.object,
};

RegisterForm.defaultProps = {
  validateFields : () => {},
  register : () => {},
  submitError : '',
  formFields : FIELDS_INITIAL_VALUE,
};

export default RegisterForm;
