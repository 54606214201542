import gql from 'graphql-tag';

export const UPDATE_PRODUCT = gql`
mutation UpdateProduct($productId: String, $updatedStocks: [UpdatedStock]) {
  updateProduct(id: $productId, updatedStocks: $updatedStocks) {
    id
    visible
    price
    stock {
      id
      price
      visible
      color {
        id
      }
    }
  }
}`;
