import React from 'react';
import Layout from '../components/layouts/OnboardingLayout.component'
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useAppContext } from '../context/AppContext'
import { goToIngresar } from '../api/navigation'
import Header from '../components/header/Header.component'
import RegisterFormContainer from '../containers/register-form/RegisterForm.container';
import { useStaticQuery, graphql } from 'gatsby';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    background:'#FFFFFF !important'
  },
}));

const PAGE_TITLE = 'Crear nueva cuenta - Carmuv';
// eslint-disable-next-line max-len
const META_DESCRIPTION = 'Creá una cuenta en Carmuv para cotizar, comparar y acceder a la mejor financiación. El proceso es simple, rápido y seguro. ¡Registrate ahora!';

const RegisterFormPage = ({ location, pageContext }) => {
  const classes = useStyles();
  const [showLogin, setShowLogin] = React.useState(true);
  const { user } = useAppContext()
  const labelLogin = user.showLoginLabel ? ' ' : "Ingresar a mi cuenta"
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            termsAndPolicy {
              policyLink
              termsLink
              loggedInTermsLink
            }
          }
        }
      }
    `
  );

  return (
    <Layout title={PAGE_TITLE} description={META_DESCRIPTION}>
        <Header
            showMobileBackIcon
            styleButtonBlack
            onGoBack={goToIngresar}
            menuLinks={[]}
            isLoggedIn={false}
            loginText={labelLogin}
          />
      <Box className={classes.container}>
        <RegisterFormContainer termsAndPolicy={site.siteMetadata.termsAndPolicy} showLogin={setShowLogin}/>
      </Box>
    </Layout>
  );
};

export default RegisterFormPage;
