import gql from 'graphql-tag';

export const SEND_SUGGESTION_EMAIL = gql`
  mutation SendSuggestionEmail($id: String, $email: String, $comments: String) {
    sendDataSheetChangeEmail(id: $id, email: $email, comments: $comments) {
      id
      applicantEmail
      applicantFirstName
      applicantLastName
      carLot {
        id
        companyId
        name
      }
      brand
      model
      version
      description
      status
      createdAt
  }
  }`;
