import React from 'react';
import Tooltip from '../Tooltip.component';
import Box from '@material-ui/core/Box';
import Text from '../text.component';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import clsx from 'clsx';

const useStyles = makeStyles( theme => ({
  tip : {
    display: 'block',
    fontSize: theme.typography.size.smaller,
    lineHeight: 1.2,
  },
  padding: {
    paddingBottom: 10,
  },
  message: {
    paddingRight: 10,
  }
}));


const PasswordError = ({ message }) => {
  const classes = useStyles();

  const SmallHelperIcon = () => (
    <Box fontSize="17px">
      <HelpOutlineIcon fontSize="inherit" color="primary" />
    </Box>
  );

  return(
    <Box display="flex" width="100%" >
      <Text className={classes.message}>{message}</Text>
      <Tooltip id={'password-error'} AnchorComponent={SmallHelperIcon}>
        <Box width="200px">
          <Text className={clsx(classes.tip, classes.padding)}>Tu clave debe contener al menos:</Text>
          <Text />
          <Text className={classes.tip}>* Una mayúscula</Text>
          <Text className={classes.tip}>* Un número</Text>
          <Text className={classes.tip}>* Al menos 6 letras</Text>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default PasswordError;
